import React from 'react'
import Link from 'gatsby-link'

export default class CommaSeparatedList extends React.Component {
  render() {
    const propItems = this.props.items;
    const type = this.props.type;
    const node = this.props.node;

    var items = [];

    // If empty array, say it's empty
    if (propItems.length < 1) {
      return ( <React.Fragment>No {type}s found</React.Fragment> );
    }

    // If the GraphQL query is edges -> node, need to replace item with item.node
    if (node) {
      for (var i = 0; i < propItems.length; i++) {
        items.push(propItems[i].node);
      }
    } else {
      items = propItems;
    }

    // If it's a link, it needs to be a normal external link, not a gatsby route
    if (type === "link") {
      return (
          <React.Fragment>
            {items.map(
              (item, i) => (
                <React.Fragment key={i}>
                  {!!i && ", "}
                  <a href={item.url}>{item.artist.name}</a>
                </React.Fragment>
              ))}
          </React.Fragment>
          );
    }

    // If it's not a link, needs to be a Gatsby link
    else {
        return (
          <React.Fragment>
          	{items.map(
              (item, i) => (
                <React.Fragment key={i}>
                	{!!i && ", "}
                	<Link to={`/${type}/${item.slug}/`}>{item.name}</Link>
              	</React.Fragment>
              ))}
          </React.Fragment>
          );
      }
  }
}