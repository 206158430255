import React from 'react'  
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import List from '../components/list'
import CommaSeparatedList from '../components/commaList'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import GigCard from '../components/card'
import {CardColumns, Badge} from 'reactstrap'
import Helmet from 'react-helmet'

const ArtistTemplate = ({ data }) => {
  const artist = data.strapiArtists;
  const gigs = data.allStrapiGigs.edges;
  const tags = artist.tags;

  return (
  <Layout>
  <Helmet
          title={`${artist.name} | Laz's Bootlegs `}
          meta={[
            { property: 'og:description', content: `Gigs by ${artist.name} in Larry's collection of bootlegs` },
            { name: 'keywords', content: `music, melbourne, ${artist.name}` },
            { property: 'og:title', content: `${artist.name} | Laz's Bootlegs`},
          ]}
        >
</Helmet>
    <h2>Gigs featuring {artist.name}</h2>
    <CardColumns>
         {gigs.map((item , i) => (
            <GigCard key={i} poster={item.node.poster} title={item.node.title} date={item.node.date} slug={item.node.slug} description={item.node.description} venue={item.node.venue} />
          ))}
        </CardColumns>
    <h2>Tags</h2>
    <div>
    {tags.map((item , i) => (
              <Badge className="pill" key={i} tag={Link} to={`/tag/${item.slug}`} color="secondary">{item.name}</Badge>
        ))}
    </div>
    <p style={{marginTop: '2em'}}><Badge href={artist.infolink} color="primary" className="pill">Find out more about {artist.name}</Badge></p>
  </Layout>
  
  )}

export default ArtistTemplate

export const query = graphql`  
query ArtistTemplate($slug: String!) {
  strapiArtists(slug: {eq: $slug}) {
    name
    tags {
      id
      name
      slug
    }
    infolink
  }

  allStrapiGigs(
    filter:
      { artists:
        { elemMatch:
          { slug:
            { in: [$slug] }
          }
        }
      },
      sort: {fields: [date], order: DESC}
    )
    {
    edges {
      node {
        slug
        poster {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        date(formatString: "DD MMMM YYYY")
        id
        title
        slug
        description
        venue {
          slug
          name
        }
      }
    }
  }
}
`
